<template>
  <div class="main-profile-package">
    <div class="row">
      <div class="col-12">
        <div class="title">{{ lbl['selfonboard-your-package'] }}</div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="package-box">
          <div class="row d-flex align-center">
            <div class="col-xl-6 col-lg-6 col-md-4 col-sm-4 col-4">
              <div class="package-name">
                {{
                  crmPackage.PackageName
                    ? crmPackage.PackageName
                    : crmPackage.PackageId
                }}
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-8">
              <a
                v-if="!crmPackage.IsFreePackage && crmPackage.IsStandardPackage"
                class="d-flex justify-end"
                @click="gotoViewPackage()"
              >
                {{ lbl['selfonboard-view-package-detail'] }}
              </a>
            </div>
            <div class="col-12 mt-3">
              <span v-if="crmPackage.IsFreePackage">
                <div class="package-price">
                  {{ lbl['selfonboard-package-free'] }}
                </div>
              </span>
              <span v-else>
                <div class="package-price">
                  {{
                    getPriceById(
                      crmPackage.PackageId,
                      crmPackage.PackagePlanType,
                    )
                  }}
                  <span
                    v-if="crmPackage.PackagePlanType == 'yearly'"
                    class="unit-price"
                  >
                    {{ lbl['selfonboard-price-per-year'] }}
                  </span>
                  <span v-else class="unit-price">
                    {{ lbl['selfonboard-price-per-month'] }}
                  </span>
                </div>
              </span>
            </div>
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    v-if="
                      data ? data.SendedQuantity || data.LimitSmsMax : false
                    "
                  >
                    <div class="d-flex align-center limit-sms">
                      {{ lbl['limit-sms'] }} {{ data.SendedQuantity }} /
                      {{ data.LimitSmsMax }}
                    </div>
                    <div
                      v-if="data ? data.LimitSmsCurrent <= 100 : false"
                      class="mt-3"
                    >
                      {{ lbl['limit-sms-less'] }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div class="d-flex justify-end align-center">
                    <img src="@/assets/images/selfonboard/time.svg" alt="" />
                    <span class="mx-3 package-ramianing">
                      {{ lbl['selfonboard-package-ramianing'] }}
                    </span>
                    <span class="package-time">
                      {{ formatFullDate(crmPackage.ExpireDate) }}
                    </span>
                  </div>

                  <div class="mt-3 d-flex justify-end">
                    <div
                      v-if="
                        crmPackage.RemainingDay <= 7 && crmPackage.IsFreePackage
                      "
                      class="d-flex align-center color-red-day"
                    >
                      <span v-if="crmPackage.RemainingDay === 0">
                        {{ lbl['selfonboard-zero-day-left'] }}
                      </span>
                      <span v-else>
                        {{ crmPackage.RemainingDay }}
                        {{ lbl['selfonboard-day-left'] }}
                      </span>
                    </div>
                    <div
                      v-else-if="
                        crmPackage.RemainingDay <= 7 &&
                        !crmPackage.IsFreePackage
                      "
                      class="d-flex align-center color-red-day"
                    >
                      <span v-if="crmPackage.RemainingDay === 0">
                        {{ lbl['selfonboard-zero-day-left'] }}
                      </span>
                      <span v-else>
                        {{ crmPackage.RemainingDay }}
                        {{ lbl['selfonboard-day-left'] }}
                      </span>
                    </div>
                    <div
                      v-else-if="!crmPackage.IsFreePackage"
                      class="d-flex align-center"
                    >
                      <span v-if="crmPackage.RemainingDay === 0">
                        {{ lbl['selfonboard-zero-day-left'] }}
                      </span>
                      <span v-else>
                        {{ crmPackage.RemainingDay }}
                        {{ lbl['selfonboard-day-left'] }}
                      </span>
                    </div>
                    <div v-else class="d-flex align-center">
                      <span v-if="crmPackage.RemainingDay === 0">
                        {{ lbl['selfonboard-zero-day-left'] }}
                      </span>
                      <span v-else>
                        {{ crmPackage.RemainingDay }}
                        {{ lbl['selfonboard-day-left'] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <span v-if="crmPackage.IsFreePackage">
          <div>
            <button @click="handleBuynow()" class="primarybtn">
              {{ lbl['selfonboard-buy-now'] }}
            </button>
          </div>
          <div
            class="mt-2 after-package-detail pointer"
            @click="handlePopupDetail()"
          >
            <img src="@/assets/images/selfonboard/question.svg" alt="" />
            {{ lbl['selfonboard-after-package-end'] }}
          </div>
        </span>
        <span v-else-if="crmPackage.IsStandardPackage">
          <div class="mt-2 after-package-detail">
            <img
              src="@/assets/images/selfonboard/selfonboard_info.svg"
              alt=""
            />
            {{ lbl['selfonboard-change-package-detail'] }}
          </div>
        </span>
      </div>
    </div>

    <span v-if="!crmPackage.IsFreePackage && isBOFowner && isPayment">
      <hr class="mt-5" />
      <div class="row">
        <div
          class="
            col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12
            d-flex
            align-center
          "
        >
          <div class="title-payment">
            <img
              src="@/assets/images/selfonboard/payment.svg"
              class="icon-payment"
              alt=""
            />
            {{ lbl['selfonboard-package-payment'] }}
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-end"
        >
          <button
            :disabled="subscriptionId == null"
            @click="handleViewPaymentDetails()"
            class="outlineprimary d-flex align-center"
          >
            {{ lbl['selfonboard-view-payment-details'] }}
            <a-icon class="ml-2" slot="prefix" type="right" />
          </button>
        </div>
      </div>
      <hr />
    </span>

    <ModalPackage
      :isOpenModal="modalPackage.isOpenModal"
      :modalType="modalPackage.modalType"
      :title="modalPackage.title"
      :details="modalPackage.details"
      :backto="modalPackage.backto"
      :confirm="modalPackage.confirm"
      :onConfirm="modalPackage.onConfirm"
      :onCancel="modalPackage.onCancel"
      :fontSize="'20'"
    />
    <ModalChangePackage
      :isOpenModal="modalChangePackage.isOpenModal"
      :modalType="modalChangePackage.modalType"
      :title="modalChangePackage.title"
      :banner="modalChangePackage.logo"
      :details="modalChangePackage.details"
      :backto="modalChangePackage.backto"
      :confirm="modalChangePackage.confirm"
      :onConfirm="modalChangePackage.onConfirm"
      :onCancel="modalChangePackage.onCancel"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import BzbsCart from '@/core/Cart/service/BzbsCart'
import BzbsPackage from '@/core/Package/service/BzbsPackage'
import ModalPackage from '@/components/Modal/Component/confirmmodal2'
import ModalChangePackage from '@/components/Modal/Component/confirmmodal2'
import Vue from 'vue'
import Cache from '@/helper/Cache.js'
import PackageHelper from '@/helper/PackageHelper.js'
import Locale from '@/helper/locale.js'

export default {
  mixins: [Mixin],
  components: {
    ModalPackage,
    ModalChangePackage,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      crmPackage: {},
      packageData: {},
      modalPackage: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        confirm: '',
        showbackto: false,
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      modalChangePackage: {
        title: '',
        logo: null,
        detail: '',
        modalType: '',
        backto: '',
        confirm: '',
        showbackto: false,
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      package: 'Basic',
      subscriptionId: null,
      isBOFowner: false,
      isPayment: false,
    }
  },
  created() {
    Account.apiAccountInformation().then(data => {
      this.crmPackage = data.data

      Account.getSubscriptionInfo().then(info => {
        if (info.length > 0) {
          if (info[0].SubscriptionId) {
            this.subscriptionId = info[0].SubscriptionId
          } else {
            this.subscriptionId = null
          }
        } else {
          this.subscriptionId = null
        }
      })
      this.init()
    })
  },
  methods: {
    init() {
      this.getPackage()
      this.checkOwner()
      this.isPayment = Vue.bzbsConfig.businessRule.open.payment
    },
    checkOwner() {
      var CrmPlusRole = Cache.get('CRMUser').CrmPlusRole
      var PosRole = Cache.get('CRMUser').PosRole
      var Role = Cache.get('CRMUser').Role
      if (
        CrmPlusRole.toLowerCase() == 'admin' &&
        PosRole.toLowerCase() == 'owner' &&
        Role.toLowerCase() == 'admin'
      ) {
        this.isBOFowner = true
      } else {
        this.isBOFowner = false
      }
    },
    getPackage() {
      if (this.crmPackage.IsStandardPackage) {
        return new Promise(resolve => {
          BzbsPackage.apiGetPackage()
            .then(res => {
              this.packageData = res.data
              console.log('packageData : ', this.packageData)
              resolve(res.data)
            })
            .catch(error => {
              console.log(error)
              resolve(error)
            })
        })
      } else {
        return new Promise(resolve => {
          BzbsPackage.apiGetNonStandardPackage()
            .then(res => {
              this.packageData = res.data
              console.log('packageData : ', this.packageData)
              resolve(res.data)
            })
            .catch(error => {
              console.log(error)
              resolve(error)
            })
        })
      }
    },
    getPriceById(packageId, planType) {
      const result = _.find(this.packageData, item => {
        if (packageId == item.PackageId) {
          return item
        }
      })
      if (result != undefined) {
        if (planType == 'yearly') {
          return result.YearlyPrice.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ',',
          )
        } else {
          return result.MonthlyPrice.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ',',
          )
        }
      }
    },
    formatFullDate(date) {
      let locale = Locale.getLocaleCode()
      if (locale != 'th') locale = 'en'
      moment.locale(locale)
      return moment.unix(date).utc().format('DD MMMM YYYY')
    },
    handleBuynow() {
      console.log('handleBuynow')
      this.$router.push({
        name: 'Package',
      })
    },
    handlePopupDetail() {
      console.log('handlePopupDetail')
      this.modalPackage.title = this.lbl['selfonboard-after-package-end']
      this.modalPackage.details =
        this.lbl['selfonboard-after-package-end-detail']
      this.modalPackage.modalType = 'question'
      this.modalPackage.backto = this.lbl['selfonboard-buy-later']
      this.modalPackage.confirm = this.lbl['selfonboard-buy-now']
      this.modalPackage.onConfirm = () => {
        this.modalPackage.isOpenModal = false
        this.$router.push({ name: 'Package' })
      }
      this.modalPackage.onCancel = () => {
        this.modalPackage.isOpenModal = false
      }
      this.modalPackage.isOpenModal = true
    },
    handleChangePackage() {
      console.log('handleChangePackage')
      let detail =
        this.lbl['selfonboard-change-package-detail'] +
        '<br /><br />' +
        this.lbl['selfonboard-change-package-example'] +
        '<br />' +
        this.lbl['selfonboard-currently-package'] +
        '<br />' +
        '<span style="color: red">' +
        this.lbl['selfonboard-not-package'] +
        '</span>'
      let details = detail

      this.modalChangePackage.title =
        this.lbl['selfonboard-change-package'] + ' ?'
      this.modalChangePackage.details = details
      this.modalChangePackage.modalType = 'warning'
      this.modalChangePackage.backto = this.lbl['confirm-box-cancel-button']
      this.modalChangePackage.confirm = this.lbl['selfonboard-change-package']
      this.modalChangePackage.onConfirm = () => {
        this.modalChangePackage.isOpenModal = false
        this.$router.push({ name: 'Package' })
      }
      this.modalChangePackage.onCancel = () => {
        this.modalChangePackage.isOpenModal = false
      }
      this.modalChangePackage.isOpenModal = true

      this.modalChangePackage.logo =
        Vue.bzbsConfig.bzbsBlobUrl +
        `/config/crmplus/package/logo_${this.crmPackage.PackageId.toLowerCase()}_${this.crmPackage.PackagePlanType.toLowerCase()}.jpg` +
        `?v=` +
        new Date().getTime()
    },
    gotoViewPackage() {
      console.log('gotoViewPackage')
      if (PackageHelper.filterPackageByPremium(this.crmPackage.PackageId)) {
        this.package = 'Premium'
        this.$router.push('/View/Package/' + this.package)
      } else {
        this.package = 'Basic'
        this.$router.push('/View/Package/' + this.package)
      }
    },
    handleViewPaymentDetails() {
      console.log('handleViewPaymentDetails')
      var params = {
        return_url: '/Account/Package?shoppingCart=true',
        success_url: '/Account/Package?shoppingCart=true',
        IsStandardPackage: this.crmPackage.IsStandardPackage
          ? this.crmPackage.IsStandardPackage
          : false,
      }
      BzbsCart.goShopingCartOrder(params, this.subscriptionId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.main-profile-package {
  .title {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: $color-grey-42;
  }

  .package-box {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 24px 20px;
    border: solid 2px $color-grey-ee;
    background-color: $color-white;

    .package-name {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: #616161;
    }

    .package-price {
      font-size: 34px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.25px;
      text-align: left;
      color: $text-dark;

      .unit-price {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.1px;
        text-align: left;
        color: $text-light-gray;
      }
    }

    .package-ramianing {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
      color: $color-grey-42;
    }

    .limit-sms {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
      color: $color-grey-42;
    }

    .package-time {
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
    }

    .color-red-day {
      color: $color-red-52;
    }
  }
  .after-package-detail {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: $color-grey-75;
    &.pointer {
      cursor: pointer;
    }
  }
  .title-payment {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: left;
    color: $color-grey-75;
  }
  .icon-payment {
    margin-top: -5px;
  }
}
</style>
