<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto">
        <div class="wrapper-ifrem custom-wrapper-ifrem">
          <div class="row paj-custom-row">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12
                pb-4
                wrapper-dashboard
                custom-wrapper-dashboard
              "
            >
              <div
                class="card-custom card-stretch gutter-b panel panel-default"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    flex-wrap
                    mr-2
                    text-title-point-adjustment
                  "
                >
                  <img
                    class="mr-2"
                    :src="require('@/assets/images/User.svg')"
                    alt=""
                  />{{ lbl['configprofile-title'] }}
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-point-adjustment">
              <div class="d-flex wrapper-select-header">
                <div
                  class="select-header"
                  :class="{ active: selectTab == 'profile' }"
                  @click="selectTab = 'profile'"
                >
                  {{ lbl['configprofile-personalprofile'] }}
                </div>
                <div
                  v-if="
                    isBOFowner &&
                    isPackage &&
                    !isBrandA &&
                    crmPackage.PackageId != filterByPackage('corporate') &&
                    crmPackage.PackageId != filterByPackage('hiveplus')
                  "
                  class="select-header select-hd-right"
                  :class="{ active: selectTab == 'package' }"
                  @click="selectTab = 'package'"
                >
                  {{ lbl['configprofile-package'] }}
                </div>
                <div
                  class="select-header select-hd-right d-none"
                  :class="{ active: selectTab == 'address' }"
                  @click="selectTab = 'address'"
                >
                  {{ lbl['configprofile-address'] }}
                </div>
              </div>
              <div class="wrapper-content">
                <div
                  v-if="selectTab == 'profile'"
                  class="wrap-table around-content-import"
                >
                  <Personalprofile />
                </div>
                <div
                  v-if="selectTab == 'package'"
                  class="wrap-table around-content-import"
                >
                  <Package v-if="!isBrandA" :data="dataProfile"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import Header from '@/components/Layout/Header'
import Package from '../configProfile/components/Package.vue'
import Personalprofile from '../configProfile/components/personalprofile.vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Account from '@/helper/AccountHelper.js'
import PackageHelper from '@/helper/PackageHelper.js'

export default {
  name: 'configprofile',
  mixins: [Mixin],
  components: {
    Header,
    Personalprofile,
    Package,
  },
  data: function () {
    return {
      selectTab: 'profile',
      isBOFowner: false,
      isPackage: false,
      isBrandA: false,
      crmPackage: {},
      isAccountOwner: false,
      dataProfile: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.handleFooter(true)
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          this.isPackage = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          this.isPackage = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        this.isPackage = Vue.bzbsConfig.businessRule.open.package
      }

      this.checkOwner()
      this.crmPackage = Account.getCachePackage()
      this.getCRMPlusProfile()
    },
    checkOwner() {
      var CrmPlusRole = Cache.get('CRMUser').CrmPlusRole
      var PosRole = Cache.get('CRMUser').PosRole
      var Role = Cache.get('CRMUser').Role
      if (
        CrmPlusRole.toLowerCase() == 'admin' &&
        PosRole.toLowerCase() == 'owner' &&
        Role.toLowerCase() == 'admin'
      ) {
        this.isBOFowner = true
      } else {
        this.isBOFowner = false
      }
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.isBrandA = res.data.AllowBrandA
            this.dataProfile = res.data
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    filterByPackage(value) {
      return PackageHelper.filterPackage(value)
    },
  },
}
</script>
<style lang="scss">
@import '../ImportBatchPoint/AdjustPoint.scss';
.around-content-import {
  .pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
@import '../ImportBatchPoint/AdjustPointScoped.scss';
.around-content-import {
  padding-top: 30px !important;
}
</style>
