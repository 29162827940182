<template>
  <div class="mainpersonalprofile">
    <div class="primary-detail">
      <div class="imagecontainer">
        <img :src="usercache.LogoUrl" alt="" />
      </div>
      <div v-if="isBeeBanefit" class="benefit-detial">
        <span class="mr-3">
          {{ lbl['setup-header-appid'] }} : {{ appId }}
        </span>
        <span> {{ lbl['setup-header-brandid'] }} : {{ brandId }} </span>
      </div>
    </div>
    <div class="formcontainer">
      <div
        class="inputcontainer"
        v-for="input in profileInput"
        :key="input.label"
      >
        <div class="inputlable">{{ lbl[input.label] }}</div>
        <input
          :disabled="input.model == 'strEmail'"
          type="text"
          :class="[
            !_self[`${input.model}State`] &&
            _self[`${input.model}State`] != null
              ? 'inputerror'
              : '',
          ]"
          :maxlength="input.maxlength"
          v-model="profileData[input.model]"
          @input="checkInput($event, input.model)"
        />
        <p
          class="dangertext"
          v-if="
            !_self[`${input.model}State`] &&
            _self[`${input.model}State`] != null
          "
        >
          {{ lbl[input.errortext] }}
        </p>
      </div>
    </div>
    <div class="mainbtncontainer">
      <button @click="handlemodal(true)" class="outlineprimary">
        {{ lbl['configprofile-changepassword'] }}
      </button>
      <button
        @click="saveEditProfile()"
        :disabled="!formState"
        class="primarybtn"
      >
        {{ lbl['company-setup-save-button'] }}
      </button>
    </div>
    <a-modal
      :title="lbl['configprofile-changepassword']"
      width="840px"
      v-model="modalChangePassword"
    >
      <div class="mainmodal">
        <div>
          <div class="inputcontainer">
            <div class="inputlable">
              {{ lbl['config-current-password-required-message'] }}
            </div>
            <a-input-password
              class="mt-2 password"
              v-model="profileData.currentPassword"
              :placeholder="lbl['config-current-password-required-message']"
            />
          </div>
          <div class="inputcontainer mt-5">
            <div class="inputlable">
              {{ lbl['user-role-manage-new-password-placeholder'] }}
            </div>
            <a-input-password
              class="password mt-2"
              v-model="profileData.password"
              :placeholder="lbl['user-role-manage-new-password-placeholder']"
            />
            <a-input-password
              class="mt-2 password"
              :class="[
                !matchPasswordState && matchPasswordState != null
                  ? 'error'
                  : '',
              ]"
              v-model="profileData.confirmPassword"
              :placeholder="lbl['configprofile-confirmpassword']"
            />
            <p
              class="dangertext"
              v-if="!matchPasswordState && matchPasswordState != null"
            >
              {{ lbl['user-permissions-passwordnotmatch'] }}
            </p>
          </div>
        </div>

        <div class="maincondition">
          <p v-for="item in condition" :key="item">
            {{ item }}
            <img
              :src="
                require(`@/assets/images/${
                  util.vMinMaxLengthPassword(profileData.password)
                    ? 'check_circle'
                    : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
        </div>
      </div>
      <template slot="footer">
        <a-button class="outlineprimary" @click="handlemodal(false)">
          {{ lbl['fgf-buttoncancel'] }}
        </a-button>
        <a-button
          class="primarybtn"
          @click="handlemodal(true, true)"
          :disabled="!changePasswordState"
        >
          {{ lbl['fgf-save'] }}
        </a-button>
      </template>
    </a-modal>

    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Mixin from '@/mixin/Mixin'
import Utility from '@/helper/Utility.js'
import Cache from '@/helper/Cache.js'
import Vue from 'vue'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import Locale from '@/helper/locale.js'
import BzbsUserPermission from '@/core/UserPermission/service/BzbsUserPermission'

export default {
  mixins: [Mixin],
  components: {
    AlertModal,
  },
  data() {
    return {
      usercache: Cache.get('CRMUser'),
      currentLocale: Locale.getLocaleShort(),
      profileData: {
        strFirstName: Cache.get('Profile').strFirstName,
        strLastName: Cache.get('Profile').strLastName,
        strContactNumber: Cache.get('Profile').strContactNumber,
        strEmail: Cache.get('Profile').strEmail,
        currentPassword: '',
        password: '',
        confirmPassword: '',
        strUsername: '',
        branchIds: null,
      },
      profileInput: [
        {
          label: 'configprofile-firstname',
          model: 'strFirstName',
          maxlength: 256,
          errortext: 'user-permissions-emailvalidate',
        },
        {
          label: 'configprofile-lastname',
          model: 'strLastName',
          maxlength: 256,
          errortext: 'user-permissions-emailvalidate',
        },
        {
          label: 'configprofile-tel',
          model: 'strContactNumber',
          maxlength: 10,
          errortext: 'user-permissions-phonevalidate',
        },
        {
          label: 'configprofile-email',
          model: 'strEmail',
          maxlength: 256,
          errortext: 'user-permissions-emailvalidate',
        },
      ],
      modalChangePassword: false,
      condition: [],
      validatePassword: {},
      util: Utility,
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      appId: '',
      brandId: '',
      isBeeBanefit: false,
    }
  },
  computed: {
    strFirstNameState() {
      if (
        this.profileData.strFirstName == '' ||
        this.profileData.strFirstName == null
      )
        return null
      return this.profileData.strFirstName.length > 0
    },
    strLastNameState() {
      if (
        this.profileData.strLastName == '' ||
        this.profileData.strLastName == null
      )
        return null
      return this.profileData.strLastName.length > 0
    },
    strContactNumberState() {
      if (
        this.profileData.strContactNumber == '' ||
        this.profileData.strContactNumber == null
      )
        return null
      return (
        this.profileData.strContactNumber.length ==
        this.profileInput[2].maxlength
      )
    },
    strEmailState() {
      if (this.profileData.strEmail == '' || this.profileData.strEmail == null)
        return null
      return (
        this.util.validateEmail(this.profileData.strEmail) &&
        this.util.vThLetter(this.profileData.strEmail)
      )
    },
    formState() {
      return (
        this.strFirstNameState &&
        this.strLastNameState &&
        this.strContactNumberState &&
        this.strEmailState
      )
    },
    matchPasswordState() {
      if (
        this.profileData.confirmPassword == '' ||
        this.profileData.confirmPassword == null
      )
        return null
      return this.profileData.password == this.profileData.confirmPassword
    },
    changePasswordState() {
      return (
        Object.keys(this.validatePassword).every(item => {
          return this.validatePassword[item](this.profileData.password)
        }) &&
        this.matchPasswordState &&
        this.profileData.currentPassword.length > 0
      )
    },
  },
  mounted() {
    this.getProfileData()
    this.getBranchList()
    this.validatePassword = {
      0: this.util.vMinMaxLengthPassword,
      1: this.util.vContainNumberOrLetter,
    }
    this.condition = this.lbl['configprofile-condition'].split('_')
    this.profileInput[2].maxlength = Vue.bzbsConfig.businessRule.max.phoneLength
  },
  created() {
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
    }
  },
  methods: {
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    getProfileData() {
      BzbsProfile.getProfileName()
        .then(res => {
          this.profileData.strFirstName = res.data.FirstName
          this.profileData.strLastName = res.data.LastName
          this.profileData.strContactNumber = res.data.Contact_Number
          this.profileData.strEmail = res.data.Email
          this.profileData.strUsername = res.data.MembershipUserName
        })
        .catch(err => console.log(err))
    },
    getBranchList() {
      BzbsProfile.getCRMPlusProfile()
        .then(res => {
          this.appId = res.data.AppId
          this.brandId = res.data.EwalletBrandId

          if (res.data.Stores) {
            this.profileData.branchIds = res.data.Stores.map(item => item.ID)
            console.log(
              'this.profileData.branchIds : ',
              this.profileData.branchIds,
            )
          }
        })
        .catch(err => console.log(err))
    },
    checkInput(e, type) {
      if (type == 'strContactNumber') {
        this.profileData[type] = e.target.value.replace(/[^0-9]/g, '')
      }
    },
    handlemodal(modastate, changestate) {
      this.modalChangePassword = modastate
      if (changestate) {
        this.saveEditProfilePassword()
      }
    },
    saveEditProfile() {
      this.handleLoading(true)
      BzbsProfile.updateProfile(this.usercache.Token, this.profileData)
        .then(() => {
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['company-setup-save-success-message'],
            'success',
            true,
          )
        })
        .catch(err => {
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            err.response.data.error.message,
            'error',
            true,
          )
        })
        .finally(() => {
          this.handleLoading(false)
        })
    },
    saveEditProfilePassword() {
      this.handleLoading(true)
      BzbsProfile.changePassword(
        this.usercache.Token,
        this.profileData.currentPassword,
        this.profileData.password,
      )
        .then(res => {
          console.log('pass : ', res)
          this.postCRMPlusUpdateUser()
        })
        .catch(err => {
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            err.response.data.error.message,
            'error',
            true,
          )
        })
        .finally(() => {
          this.handleLoading(false)
        })
    },
    postCRMPlusUpdateUser() {
      console.log('postCRMPlusUpdateUser true')
      let form = {
        password: this.profileData.password,
        confirmPassword: this.profileData.password,
        firstName: this.profileData.strFirstName,
        lastName: this.profileData.strLastName,
        userName: this.profileData.strUsername,
        userNameApi: this.profileData.strUsername,
        email: this.profileData.strEmail,
        contactNumber: this.profileData.strContactNumber,
        branchIds: this.profileData.branchIds,
        role: Cache.get('CRMUser').CrmPlusRole
          ? Cache.get('CRMUser').CrmPlusRole
          : null,
        oldRole: Cache.get('CRMUser').CrmPlusRole
          ? Cache.get('CRMUser').CrmPlusRole
          : 'admin',
      }

      BzbsUserPermission.postCRMPlusUpdateUser(form)
        .then(() => {
          console.log('postCRMPlusUpdateUser true 2')
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['configprofilesuccesstext'],
            'success',
            true,
          )
          this.modalChangePassword = false
        })
        .catch(err => {
          console.log(err)
          console.log('postCRMPlusUpdateUser err 3')
        })
        .finally(() => this.handleLoading(false))
    },
  },
}
</script>

<style lang="scss" scoped>
.mainpersonalprofile {
  .primary-detail {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    .imagecontainer {
      width: 167px;
      height: 167px;
      background-color: #eee;
      border: 1px solid #eee;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .formcontainer {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    max-width: 900px;
    gap: 24px;
    flex-wrap: wrap;
    .inputcontainer {
      .inputlable {
        font-size: 14px;
        font-weight: bold;
        color: #424242;
      }
      input {
        width: 300px;
        height: 40px;
        padding: 8px 12px;
        border-radius: 4px;
        border: solid 1px #d9d9d9;
        background-color: #fff;
        &:focus {
          outline: none;
        }
        &.inputerror {
          border: 1px solid #ff5252;
        }
        &:disabled {
          background-color: #eee;
        }
      }
    }
  }
  .mainbtncontainer {
    margin-top: 24px;
    display: flex;
    gap: 24px;
  }
}
.dangertext {
  font-size: 14px;
  color: #ff5252;
  text-align: left;
}
.mainmodal {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  .inputlable,
  .maincondition {
    font-size: 14px;
    color: #424242;
    text-align: left;
  }
  .maincondition {
    p:first-child {
      margin-top: 146px;
    }
  }
}
@media only screen and (max-width: 627px) {
  .mainmodal {
    .maincondition {
      p:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.mainpersonalprofile {
  .password.error {
    input {
      border: 1px solid #ff5252;
    }
  }
}
</style>
